import axios from "./api"

export const PostFile = (payload, document) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${document}/document`, payload)
      .then(response => {
        resolve({ data: response.data, status: response.status })
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const PostProfileImage = (payload, id) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${id}/photo`, payload)
      .then(response => {
        resolve({ data: response.data, status: response.status })
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const profileImage = id => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/${id}/photo`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postElectronicId = (id, loanId, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/client/${id}/loan/${loanId}/electronicid/status`, payload)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const crmSendInfo = (c_cedula, name, c_celular, c_Estado) => {
  let payload = { c_cedula, name, c_celular, c_Estado }

  return new Promise((resolve, reject) => {
    axios
      // add env var for crm conexion - set meanwhile
      .post(
        `https://crm.telephant.co/crm/x2engine/index.php/api2/Grupoctl`,
        payload,
        {
          auth: {
            // temporal vars, add in  env vars
            username: "rajaram",
            password: "gXYCgrmOmGQ8I5pnrDugLlBdMquL4djL"
          }
        }
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        console.log(error)
        reject(false)
      })
  })
}
