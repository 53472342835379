export const getError = state => {
  return {
    error: state.error,
    errorMessage: state.errorMessage
  }
}

export const isError = state => {
  return state.error
}
