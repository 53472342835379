export const currentStatus = state => {
  let current_status = state.formSteps.find(
    formStep => formStep.completed == false
  )
  return current_status.step
}

export const isStepCompleted = state => step => {
  let check_step = state.formSteps.find(formStep => formStep.step == step)
  return check_step.completed
}

export const enabledClick = state => {
  return state.info
    ? state.info.awaitingPersonalInformation == "DONE"
      ? true
      : false
    : false
}

export const getBasicInfo = state => {
  return state.info
}

export const getBasicUuidInfo = state => {
  return state.uuid
}

export const getImages = state => {
  return state.images
}

export const getPending = state => {
  return state.pending
}

export const getPersonalInformation = state => {
  return state.personalInformation
}

export const getLaboralInformation = state => {
  return state.laboralInformation
}

export const getEmail = state => {
  return state.info ? state.info.email : ""
}

export const getDocumentNumber = state => {
  return state.info ? state.info.cc : ""
}

export const getPhone = state => {
  return state.ubicaSelected
    ? state.ubicaSelected.phone.phone
    : state.info.phone
}

export const getLoans = state => {
  return state.loans
}

export const getLoanForBankAccounts = state => {
  let loan = state.loans.find(loan =>
    [102, 103, 104, 200, 201, 202, 203, 300].includes(loan.status.id)
  )
  return loan
}

export const getApprovedAwaitingSignature = state => {
  let approvedAwaitingSignature = state.loans.find(
    loan => loan.status.id == 201
  )
  return approvedAwaitingSignature
}

export const getPendingIdValidation = state => {
  let pendingIdValidation = state.loans.find(loan => loan.status.id === 102)
  return pendingIdValidation
}

export const getAwaitingApproval = state => {
  let pendingRequest = state.loans.find(loan => loan.status.id == 100)
  return pendingRequest
}

export const getApprovedOrApprovedAwaitingSignature = state => {
  let pendingRequest = state.loans.find(loan =>
    [200, 201].includes(loan.status.id)
  )
  return pendingRequest
}

export const getloanId = state => loandId => {
  return state.loans.find(loan => loan.id === loandId)
}
//loan en proceso, para volver a monto y plazo
export const getloanActive = state => {
  return state.loanActive
}

//Credito para Scrapping
export const getLoadScrapping = state => {
  let loadId = state.loans.find(loan => loan.status.id == 101)
  return loadId
}

//Credito para Pre desembolso
export const getLoadPredisburse = state => {
  let loadId = state.loans.find(loan => loan.status.id == 203)
  return loadId
}

//Credito que esta en "submitted and awaiting confronta" 103
export const getLoadSubmittedAndAwaitingConfronta = state => {
  let loadId = state.loans.find(loan => loan.status.id == 103)
  return loadId
}

//Credito que esta en "submitted and awaiting Request 104
export const getLoadSubmittedAndAwaitingRequest = state => {
  let loadId = state.loans.find(loan => loan.status.id == 104)
  return loadId
}

export const getUbicaPhones = state => {
  return state.ubica.phones || []
}

export const getUbicaEmails = state => {
  return state.ubica.emails || []
}

export const getUbicaAddresses = state => {
  return state.ubica.addresses || []
}

export const getUbica = state => {
  return (
    state.ubica || {
      userInformation: {},
      addresses: [],
      emails: [],
      phones: []
    }
  )
}

export const getUbicaSelected = state => {
  return state.ubicaSelected
}

export const getGenderSelecters = state => {
  return state.selectors.genderId || []
}

export const getEducationalLevelSelecters = state => {
  return state.selectors.educationalLevel || []
}

export const getMaritalStatus = state => {
  return state.selectors.maritalStatus || []
}

export const getHousingType = state => {
  return state.selectors.housingType || []
}

export const getStayTime = state => {
  return state.selectors.stayTime || []
}

export const getFamilyReference = state => {
  return state.selectors.familyReference || []
}

export const getPersonalReference = state => {
  return state.selectors.personalReference || []
}

export const getJobType = state => {
  return state.selectors.jobType || []
}

export const getEconomicSector = state => {
  return state.selectors.economicSector || []
}

export const getBank = state => {
  return state.selectors.bank || []
}

export const getJtaValidations = state => {
  return state.selectors.jtaValidation || []
}

export const getAccountType = state => {
  return state.selectors.accountTypeId || []
}

export const getPaymentPeriodicity = state => {
  return state.selectors.paymentPeriodicity || []
}

export const getPaymentMethod = state => {
  return state.selectors.paymentMethod || []
}

export const getEconomicActivity = state => {
  return state.selectors.economicActivity || []
}

export const getOtherIncome = state => {
  return state.selectors.otherIncome || []
}

export const getGender = state => genderId => {
  return state.selectors.genderId.find(loan => loan.value === genderId)
}

export const getBuroResult = state => {
  return state.selectors.buroResult || []
}

export const getDepartments = state => {
  return state.departments || []
}

export const getMinMonthSalary = state => workingStatus => {
  return (
    state.selectors.minIncomes.find(
      jobType => jobType.workingStatus === workingStatus
    )?.minIncome || 0
  )
}

export const getLoanPurposeSelecters = state => {
  return state.selectors.loanPurpose || []
}

export const getYesOrNo = state => {
  return state.selectors.yesOrNo || []
}

export const getConfrontaQuestion = state => questionId => {
  return state.confrontaQuestions.find(
    question => question.index === questionId
  )
}

export const getConfrontaQuestions = state => {
  return state.confrontaQuestions
}

export const getTotalQuestions = state => {
  return state.confrontaQuestions[0].totalQuestions || 0
}

export const getEventType = state => {
  return state.selectors.eventType || []
}

export const getPhotoProfile = state => {
  return state.photoProfile?.b64ImageData || null
}
