import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Main",
    redirect: () => {
      return store.getters["auth/isAuthenticated"] ? "/user" : "/login"
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
    meta: { title: "JTA - Login" }
  },
  {
    path: "/user",
    name: "User",
    component: () => import(/* webpackChunkName: "user" */ "@/views/User.vue"),
    meta: { requiresAuth: true, title: "JTA - Usuarios" }
  },
  {
    path: "/telephantTest",
    name: "telephantTest",
    component: () => import(/* webpackChunkName: "Janues" */ "@/components/user/janus/TelephantPublic.vue"),
    meta: { title: "JTA - Telephant" }
  },
  {
    path: "/telephant",
    name: "telephant",
    component: () => import(/* webpackChunkName: "Janues" */ "@/components/user/janus/Telephant.vue"),
    meta: { title: "JTA - Telephant" }
  },
  {
    path: "/table",
    name: "table",
    component: () => import(/* webpackChunkName: "Janues" */ "@/components/user/janus/Table_veriff.vue"),
    meta: { requiresAuth: true, title: "JTA - table" }
  },
  {
    path: "/veriff",
    name: "veriff",
    component: () => import(/* webpackChunkName: "Janues" */ "@/components/user/janus/Validation_veriff.vue"),
    props: true,
    meta: { title: "JTA - Veriff" }
  },
  { 
    path: "*", 
    redirect: () => { return store.getters["auth/isAuthenticated"] ? "/user" : "/login"
  }
}]

const router = new VueRouter({
  mode: "history",
  linkExactActiveClass: "is-active",
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (to.matched.some(m => m.meta.requiresAuth)) {
    if (store.getters["auth/isAuthenticated"]) {
      next()
    } else {
      next({
        name: "Login",
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

export default router
