import jwtDecode from "jwt-decode"
import { obtainToken, updateToken } from "../../services/auth.js"
import axios from "../../services/api"

export const login = ({ commit, dispatch }, { username, password }) => {
  return new Promise((resolve, reject) => {
    obtainToken(username, password)
      .then(data => {
        commit("setToken", data.accessToken)
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.accessToken}`
        var decoded = jwtDecode(data.accessToken)
        commit("setUsername", decoded.username.toString())
        commit("setPermission", decoded.roles.toString())
        resolve()
      })
      .catch(error => {
        dispatch("logout")
        reject(error)
      })
  })
}

export const logout = ({ commit }) => {
  commit("removeToken")
  commit("removePermission")
}

export const refreshToken = ({ state, commit, dispatch }) => {
  if (!state.token) {
    dispatch("logout")
    return
  }

  updateToken(state.token)
    .then(data => {
      commit("setToken", data.token)
    })
    .catch(() => {
      dispatch("logout")
    })
}
