import axios from "./api"

export const obtainToken = (username, password) => {
  const payload = {
    username: username,
    password: password
  }
  return new Promise((resolve, reject) => {
    axios
      .post("/login", payload)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const updateToken = token => {
  const payload = {
    token
  }
  return new Promise((resolve, reject) => {
    // TODO: NO DEFINIDO AUN EL REFRESH
    axios
      .post("/api-token-refresh", payload)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}
