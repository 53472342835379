import axios from "./api"
import dayjs from "dayjs"
import "dayjs/locale/es"
dayjs.locale("es")

/**
 * @type {(documentNumber: string, surname: string, documentType: string) => Promise}
 */
export const getDataFromUbica = (documentNumber, surname) => {
  return new Promise((resolve, reject) => {
    let payload = {
      id: documentNumber,
      surname: surname,
      idType: "cc"
    }
    axios
      .post("/validation/ubica", payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(documentNumber: string, givenname: string, surname: string, lastname: string) => Promise}
 */
export const validateNames = (documentNumber, givenname, surname, lastname) => {
  return new Promise((resolve, reject) => {
    let payload = {
      cc: documentNumber,
      givenname: givenname,
      surname: surname,
      lastname: lastname
    }
    axios
      .post("/validation/names", payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(documentNumber: string, email: string, phone: string) => Promise}
 */
export const validateEmailPhone = (documentNumber, email, phone) => {
  return new Promise((resolve, reject) => {
    let payload = {
      cc: documentNumber,
      email: email,
      phone: phone
    }
    axios
      .post("/validation/data", payload)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })
}

/**
 * @type {(documentNumber: string, loanId: number, indexQuestion: number) => Promise}
 */
export const getQuestion = (documentNumber, loanId, indexQuestion) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/client/${documentNumber}/loan/${loanId}/questionnaire/${indexQuestion}`
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error))
  })
}

/**
 * @type {(documentNumber: string, loanId: number, answers: Array) => Promise}
 */
export const postAnswers = (documentNumber, loanId, answers) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/client/${documentNumber}/loan/${loanId}/questionnaire/answers`,
        answers
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error))
  })
}

export const riskLevelMatrix = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/buro/riskLevelMatrix`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error))
  })
}

/**
 * @type {(
 *  codResponse: string, 
 * finalPath: string, 
 * fineractId: number, 
 * incomeFactor: string, 
 * loanId: number, 
 * risk: string, 
 * score: string, 
 * status: string
 * mark: string;
 * maxRiskQuota: number;
 * ) => Promise}
 */
export const buroGet = (documentNumber, loanId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/${documentNumber}/loan/${loanId}/buro`)
      .then(response => {
        resolve({ data: response.data, status: response.status })
      })
      .catch(error => reject(error))
  })
}

export const firstDatePayment = async () => {
  const [SAT, SUN] = [6, 0] // invalid days
  const [_1, _16] = [1, 16] // valid dates

  let data = await axios.get(`/selectors/holidays`)
  let holidays = await data.data.map(obj => obj.label)
  let day = dayjs() //today

  day = day.add(30, "day") //Next month

  if (day.date() <= _16) {
    day = day.set("date", _16)
  } else {
    day = day.add(1, "month").set("date", _1)
  }

  let sw = false
  while (sw === false) {
    let day_format = day.format("YYYY/MM/DD")
    if (
      !holidays.includes(day_format) &&
      SAT !== day.day() &&
      SUN !== day.day()
    ) {
      sw = true
    } else {
      day = day.add(1, "day")
    }
  }
  return day
}


export const getWorkingtype = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/workingtype/minIncome`)
      .then(response => {
        resolve({ data: response.data, status: response.status })
      })
      .catch(error => reject(error))
  })
}

export const getSimulator = (documentNumber) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/client/buro/simulator/factors/${documentNumber}`)
      .then(response => {
        resolve({
          ...response.data,
          interest: response.data.interest * 0.01,
          guaranteeConstant: response.data.guaranteeConstant * 0.01,
          guaranteeVariableWoman: response.data.guaranteeVariableWoman * 0.01,
          guaranteeVariableMan: parseFloat(
            (response.data.guaranteeVariableMan * 0.01).toFixed(5)
          )
        })
      })
      .catch(error => reject(error))
  })
}

export const loans = async (loanId, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/loan/${loanId}`, payload)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error))
  })
}
