import axios from "axios"
import { Notyf } from "notyf"
import store from "../store"
import router from "../router"

class CustomError {
  constructor(error) {
    if (error.response) {
      this.status = error.response.status || "Unknown"
      this.code = error.response.data.code || ""
      this.message =
        error.response.data.message ||
        error.response.data.errorDescription ||
        error.message ||
        "Unknown Error"
    } else {
      this.status = "Unknown"
      this.code = ""
      this.message = error
    }
  }
  toString() {
    return this.message
  }
}

const env = process.env

let API_HOST = `${env.VUE_APP_API_HOST}`

const token = localStorage.getItem("user-token")
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

axios.defaults.baseURL = API_HOST
axios.defaults.headers.post["Content-Type"] = "application/json"

axios.interceptors.response.use(undefined, error => {
  if (error.response.status === 401 && error.config.url !== "/login") {
    store.dispatch("auth/logout")
    store.dispatch("user/cleanData")
    let notyf = new Notyf({
      duration: 7000,
      dismissible: true,
      position: { x: "center", y: "top" }
    })
    notyf.error("La sesión ha caducado.")
    router.push("/login")
  }
  let customError = new CustomError(error)
  return Promise.reject(customError)
})

export default axios
