import jwtDecode from "jwt-decode"

export const isAuthenticated = state => {
  if (!state.token) {
    return false
  }

  const tolerance = 60 * 3 // 2 min of tolerance
  const exp = jwtDecode(state.token).exp + tolerance
  return Date.now() / 1000 < exp
}

export const getUsername = state => {
  return state.username
}

export const getPermission = state => {
  return state.permission;
}
