import Vue from "vue"
import Vuex from "vuex"
import auth from "./auth"
import user from "./user"
import form from "./form"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    user,
    form
  }
})

export default store
