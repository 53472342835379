import Vue from "vue"
import VueRouter from "vue-router"
import Vuelidate from "vuelidate"
import VueScrollTo from "vue-scrollto"
import { Notyf } from "notyf"
import "notyf/notyf.min.css"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faSignOutAlt,
  faUser,
  faLock,
  faDollarSign,
  faCreditCard,
  faPhoneAlt,
  faUpload,
  faCheckCircle,
  faTimesCircle,
  faCopy,
  faSearch,
  faTable
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import VueDayjs from "vue-dayjs-plugin"
import setVersion from "./helpers/setVersion"

Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(VueDayjs)
Vue.use(VueScrollTo)
setVersion()

Vue.prototype.$notyf = new Notyf({
  duration: 5000,
  dismissible: true,
  position: { x: "center", y: "top" }
})

library.add(faUser, faLock, faDollarSign, faCreditCard, faPhoneAlt, faSignOutAlt, faUpload, faCheckCircle,  faTimesCircle, faCopy, 
            faSearch, faTable)

Vue.component("font-awesome-icon", FontAwesomeIcon)

Vue.config.productionTip = false

Vue.directive("focus", {
  inserted: function(el) {
    el.focus()
  }
})

new Vue({
  router,
  store,
  devtool: "source-map",
  render: h => h(App)
}).$mount("#app")
