import {
  getClientStatus,
  getClientUuid,
  getSelectors,
  getDepartamentCodes,
  addLaboralInformation,
  addPersonalInformation,
  loanDesist,
  undoLoanDesist,
  loanDoneScrapping,
  predisbursementPost,
  bankStatementsPolicy,
  sendLastDesicion,
  postPending,
  getAlternativeImages
} from "../../services/user"

import {
  PostFile,
  PostProfileImage,
  profileImage,
  postElectronicId,
} from "../../services/telephant"

import {
  getDataFromUbica,
  getQuestion,
  postAnswers,
  buroGet,
  loans,
  getWorkingtype
} from "@/services/validation"


export const getClient = ({ dispatch, commit }, { value, method }) => {
  return new Promise((resolve, reject) => {
    getClientStatus(value, method)
      .then(data => {

        commit("initialForm")
        commit("completeFormStep", "search")

        const InitialStatus = [100, 101, 102, 103, 104, 200, 201, 202, 203]; 
        let loanActive = data.loans.filter(loan => InitialStatus.includes(loan.status.id))
        
        //cliente cerrado con causal fraude/suplantacion, datos falsos, lista negra
        if (data.clientStatus.id === 600 && [1314, 1317, 1368].includes(data.clientClosureReason?.id || 0)) {
          reject('¡Lo sentimos! En este momento no cumples con los requisitos de ingreso.')
          return;
        }

        //cliente cerrado por su solicitud (habeas data)
        if (data.clientStatus.id === 600 && [1315].includes(data.clientClosureReason?.id || 0)) {
          reject('Necesitamos verificar tus datos, Por favor comunícate con nosotros')
          return;
        }

        
        //Ningun credito en proceso
        if (loanActive.length === 0) {
          commit(
            "form/setError",
            {
              errorStatus: true,
              errorMessage: "Usuario no tiene solicitudes en proceso en este momento."
            },
            { root: true }
          )
        }

        let loanSelected = loanActive.length > 0 ? loanActive[0] : data.loans[0]
        commit("addLoanActive", loanSelected)
        commit("completeFormStep", "desist")

        if (data.info.fineractId !== -1) {
          commit("completeFormStep", "basicInformation")
          commit("completeFormStep", "ubica")
          commit("completeFormStep", "generateOTP")
          dispatch("getUbica", {
            documentNumber: data.info.cc,
            surname: data.info.surname
          })
        } else {
          data.info.cc = value
        }
        
        if (data.info.awaitingPersonalInformation == "DONE") {
          commit("completeFormStep", "personalInformation")
          commit("completeFormStep", "residentialInformation")
          // commit("completeFormStep", "references")
          commit("completeFormStep", "laboralInformation")
        }

        // if (data.info.awaitingDisclaimer == "DONE") {
        //   commit("completeFormStep", "disclaimer")
        // }

        if(data.loans.find( loan => [102, 103, 104, 200, 201, 202, 203, 300].includes(loan.status.id))){
          commit("completeFormStep", "scraping")
        }

        let id_valida = [102, 103, 200, 201, 202, 203, 300]
        data.loans.find(loan => {
          if (
            loan.amount !== 1 &&
            loan.loanPurpose !== 0 &&
            id_valida.includes(loan.status.id)
          ) {
            commit("completeFormStep", "simulator")
          }
        })

        if (data.info.awaitingIdentityValidation == "DONE") {
          commit("completeFormStep", "identityValidation")
        }

        if (data.info.awaitingSecurityQuestion == "DONE") {
          commit("completeFormStep", "confronta")
        }

        //si está pre desembolsado
        if (data.loans.find(loan => [203].includes(loan.status.id))) {
          commit("completeFormStep", "bankInformation")
          commit("completeFormStep", "disbursementMethod")
          commit("completeFormStep", "approvedAwaitingSignature")
        }

        commit("setStatus", data.status)
        commit("setRegistrationAttemps", data.registrationAttemps)
        commit("setPersonalInformation", data.personalInformation)
        commit("setLaboralInformation", data.laboralInformation)
        commit("setLoans", data.loans)
        commit("setConfronta", data.confronta)
        commit("setInfo", data.info)
        commit("setIdentityValidationAttemps", data.identityValidationAttemps)

        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const updateStatus = ({ commit }, documentNumber) => {
  return new Promise((resolve, reject) => {
    getClientStatus(documentNumber,"id")
      .then(data => {
        //actualizar parametros para definir en que pantalla está
        commit("setLaboralInformation",data.laboralInformation)
        commit("setLoans", data.loans)
        commit("setInfo", data.info)
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getClientLoans = ({ commit }, { documentNumber }) => {
  return new Promise((resolve, reject) => {
    getClientStatus(documentNumber,"id")
      .then(data => {
        commit("setLoans", data.loans)
        resolve(data.loans)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getUbica = ({ commit }, { documentNumber, surname }) => {
  return new Promise((resolve, reject) => {
    getDataFromUbica(documentNumber, surname)
      .then(data => {
        commit("setUbica", data)
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getSelectorForm = ({ commit }) => {
  return new Promise((resolve, reject) => {
    getSelectors()
      .then(data => {
        commit("setSelectors", data)
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getWorkingtypeForm = ({commit}) => {
  return new Promise((resolve, reject) => {
    getWorkingtype()
      .then(resp => {
        commit("addSelector", {
          minIncomes: resp.data
        })
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getDepartments = ({ commit }) => {
  return new Promise((resolve, reject) => {
    getDepartamentCodes()
      .then(data => {
        commit("setDepartments", data)
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postLaboralInformation = (
  { commit },
  { documentNumber, payload }
) => {
  return new Promise((resolve, reject) => {
    addLaboralInformation(documentNumber, payload)
      .then(() => {
        commit("setLaboralInformation", payload)
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const postPersonalInformation = (
  { commit },
  { documentNumber, payload }
) => {
  return new Promise((resolve, reject) => {
    addPersonalInformation(documentNumber, payload)
      .then(() => {
        commit("completeFormStep", "personalInformation")
        commit("completeFormStep", "residentialInformation")
        // commit("completeFormStep", "references")
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const getConfrontaQuestions = (
  { commit, getters },
  { documentNumber, loanId, indexQuestion }
) => {
  return new Promise((resolve, reject) => {
    let question = getters.getConfrontaQuestion(indexQuestion)
    if (question === undefined) {
      getQuestion(documentNumber, loanId, indexQuestion)
        .then(data => {
          data.index = indexQuestion
          data.answerSelected = undefined
          commit("setConfrontaQuestions", data)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    } else {
      resolve(question)
    }
  })
}

export const postConfrontaAnswers = (
  context,
  { documentNumber, loanId, answers }
) => {
  return new Promise((resolve, reject) => {
    postAnswers(documentNumber, loanId, answers)
      .then(data => resolve(data))
      .catch(error => reject(error))
  })
}

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const buroRequest = async (context, { documentNumber, loanId }) => {
  for (;;) {
    try {
      let response = await buroGet(documentNumber, loanId)
      if (response.status === 200) {
        return { hasBuro: true, ...response }
      }
    } catch (e) {
      if (e.status === 400) {
        return { hasBuro: false, status: e.status, ...e.payload }
      }
    }
    await sleep(2000)
  }
}

export const getBuro = (context, { documentNumber, loanId }) => {
  return new Promise((resolve, reject) => {
    buroGet(documentNumber, loanId)
      .then(data => resolve(data))
      .catch(error => reject(error))
  })
}

export const patchLoans = ({ commit }, { payload, loanId }) => {
  return new Promise((resolve, reject) => {
    loans(loanId, payload)
      .then(data => {
        commit("completeFormStep", "simulator")
        resolve(data)
      })
      .catch(error => reject(error))
  })
}

export const updateConfrontaQuestion = ({ commit }, question) => {
  commit("updateConfrontaQuestion", question)
}

export const cleanData = ({ commit }) => {
  commit("removeStatus")
  commit("removeRegistrationAttemps")
  commit("removePersonalInformation")
  commit("removeLaboralInformation")
  commit("removeLoans")
  commit("removeConfronta")
  commit("removeIsnfo")
  commit("removeIdentityValidationAttemps")
  commit("removeUbica")
  commit("initialForm")
  commit("removeUbicaSelected")
  commit("removeConfrontaQuestions")
  commit("removePhotoProfile")
  commit("removeUuid")
  commit(
    "form/setError",
    { errorStatus: false, errorMessage: "" },
    { root: true }
  )
}

export const postLoanDesist = (context, loanId) => {
  return new Promise((resolve, reject) => {
    loanDesist(loanId)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export const postLoanDoneScrapping = (context, loanId) => {
  return new Promise((resolve, reject) => {
    loanDoneScrapping(loanId)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export const postUndoLoanDesist = (context, loanId) => {
  return new Promise((resolve, reject) => {
    undoLoanDesist(loanId)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export const postPredisbursement = ({getters}, payload) => {
  let documentNumber = getters.getDocumentNumber;
  let loanId = getters.getLoadPredisburse.id
  return new Promise((resolve, reject) => {

    if(!loanId) reject("Credito inválido")
    if(!documentNumber) reject("Sin documento de identidad")

    predisbursementPost(documentNumber, loanId, payload)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}


export const getUuidInfo = ({commit}, {uuid, loanId}) => {
  return new Promise((resolve, reject) => {
    getClientUuid(uuid, loanId)
      .then(data => {
        commit("setUuid", data)
        resolve(data)
      })
      .catch(err => reject(err))
  })
}

export const getImages = ({commit}, {loanId, sessionId}) => {
  return new Promise((resolve, reject) => {
    getAlternativeImages(loanId, sessionId)
      .then(data => {
        commit("setImages", data)
        resolve(data)
      })
      .catch(err => reject(err))
  })
}

export const postLastDesicion = (context, payload) => {
  return new Promise((resolve, reject) => {
    sendLastDesicion(payload)
      .then(data =>
       resolve(data))
      .catch(err => reject(err))
  })
}

export const postPendingTable = ({commit}, {pageNumber, pageSize, order, payload}) => {
  return new Promise((resolve, reject) => {
    postPending(pageNumber, pageSize, order, payload)
      .then(data => {
        commit("setPending", data)
        resolve(data)
      })
      .catch(err => reject(err))
  })
}


/******************** TELEPHANT ************************/

export const SendFiles = (context, {file, documentNumber}) => {
  return new Promise((resolve, reject) => {
    PostFile(file, documentNumber)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export const SendProfileImage = (context, {image, documentNumber}) => {
  return new Promise((resolve, reject) => {
    PostProfileImage(image, documentNumber)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export const getProfileImage = ({commit}, documentNumber) => {
  return new Promise((resolve, reject) => {
    profileImage(documentNumber)
      .then(data => {
        commit("setPhotoProfile", data)
        resolve(data)
      })
      .catch(err => reject(err))
  })
}

export const sendElectronicId = (context, {documentNumber, loanId, payload}) => {
  return new Promise((resolve, reject) => {
    postElectronicId(documentNumber, loanId, payload)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}

export const sendBankStatementsPolicy = (context, {documentNumber, loanId, payload}) => {
  return new Promise((resolve, reject) => {
    bankStatementsPolicy(documentNumber, loanId, payload)
      .then(data => resolve(data))
      .catch(err => reject(err))
  })
}



