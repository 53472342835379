export const setToken = (state, newToken) => {
  localStorage.setItem("user-token", newToken)
  state.token = newToken
}

export const removeToken = state => {
  localStorage.removeItem("user-token")
  state.token = null
}

export const setPermission = (state, role) => {
  localStorage.setItem("permission", role)
  state.permission = role
}

export const removePermission = state => {
  localStorage.removeItem("permission")
  state.permission = []
}

export const setUsername = (state, username) => {
  localStorage.setItem("username", username)
  state.username = username;
}

export const removeUsername = (state) => {
  localStorage.removeItem("username")
  state.username = null;
}
